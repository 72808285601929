import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/Layout'

import styles from './about.module.css'
import SEO from '../components/SEO/SEO'

export const PageTemplate = props => {
  return (
    <section className={styles.container}>
      <div className={styles.page}>
        <div className={styles.headerContainer}>
          <h1 className={styles.header}>WE ARE CHANGING THE CONVERSATION</h1>
        </div>
        <div className={styles.content1}>
          <h2 className={styles.content1Left}>
            Lengjai is unlike any other fashion/culture website. Really.
          </h2>
          <div className={styles.content1Right}>
            Tired of seeing alt-right crap dominating your feeds? We are too.
            We're pretty woke and are probably the hardest-working people you
            will ever find. We're used to being in the top 1% in everything we
            do, and our standard of excellence drives Lengjai.
          </div>
        </div>
        <div className={styles.content1}>
          <h2 className={styles.content1Left}>What does 'Lengjai mean?'</h2>
          <div className={styles.content1Right}>
            Translated literally from Cantonese: 'good-looking/handsome boy.'
            Lengjai is an inclusive space for all everybody but of
            course, we're Asian-focused for all genders.
          </div>
        </div>
        <div className={styles.content2}>
          <div className={styles.content2Left}>
            <div className={styles.imageContainer}>
              <Img
                fluid={props.data.image1.childImageSharp.fluid}
                alt={''}
                className={styles.image}
              />
            </div>
            <div className={styles.imageDescription}>
              <h2>Sean Ng Pack</h2>
              <div>Mechanical Engineering junior @ Northeastern</div>
              <div>
                Fashion, photography, design, computer science, robotics,
                esoteric hobbies--obsessive personality and will not stop until
                complete mastery
              </div>
            </div>
          </div>
          <div className={styles.content2Right}>
            <div className={styles.imageContainer}>
              <Img
                fluid={props.data.image2.childImageSharp.fluid}
                alt={''}
                className={styles.image}
              />
            </div>
            <div className={styles.imageDescription}>
              <h2>Philip Ng Pack</h2>
              <div>4.7 GPA junior in High School</div>
              <div>
                Varsity debate captain, varsity tennis player, adept
                writer--exceptionally rare logic and reasoning abilities
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const Page = ({ data }) => {
  return (
    <Layout>
      <SEO
        // FIX THE SEO!!!!
        title="About Lengjai"
        image={data.featuredImage.childImageSharp.fluid.src}
        description="Lengjai delivers unparalled levels of woke Asian fashion and culture news"
        pathname="https://www.lengjai.com/about"
        article
      />
      <PageTemplate data={data} />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    image1: file(relativePath: { eq: "seanngpack.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "philipngpack.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featuredImage: file(relativePath: { eq: "about.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
